import React, { useEffect, useRef, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  IconButton,
  useDisclosure,
  Image,
} from "@chakra-ui/react";

import { DeleteIcon, EditIcon, CheckIcon } from "@chakra-ui/icons";

import "./style.css";
import Alert from "../../../../components/Alert";
import Drawer from "../../../../components/Drawer";
import { updateActiveEvent, updateEvents } from "../../../../services/api";
import { useToast } from "@chakra-ui/react";
import EventForm from "../EventForm";
import { IEvent } from "../../../../interfaces/event-interface";
import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment-timezone';

interface EventTableProps {
  events: IEvent[];
  list: () => void;
}

export default function EventTable({
  events,
  list,
}: EventTableProps): JSX.Element {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
    onClose: onCloseDrawer,
  } = useDisclosure();
  const cancelRef = useRef();
  const toast = useToast();

  const [event, setEvent] = useState<IEvent>();
  const [isActive, setIsActive] = useState(false);

  function middlewareEdit(event: IEvent) {
    setEvent(event);
    onOpenDrawer();
  }

  function middlewareActive(event: IEvent) {
    setEvent(event);
    setIsActive(!event.is_active)
    onOpen();
  }
  function DateFormat (date: React.ReactNode) {
    const dateFormated = new Date(date as string);
    const day = dateFormated.getDate();
    const month = dateFormated.getMonth() + 1;
    const year = dateFormated.getFullYear();
 

    return `${day}/${month}/${year} `;
  }
  function handleConfirmActive() {
    if (!event?.id) return;

    updateActiveEvent(event.id)
      .then((res) => {
        list();
        onClose();
        if(res.data.error){
          toast({
            title: res.data.message,
            status: "error",
            duration: 4000,
            isClosable: true,
          })
        }else{
          toast({
            title: "Evento atualizado com sucesso",
            status: "success",
            duration: 4000,
            isClosable: true,
          })
        }
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar evento.",
          description: "Verifique se o evento foi atualizado.",
          status: "error",
          duration: 4000,
          isClosable: true,
        });
      });
  }

  function handleUpdate(data: IEvent) {
    if (!event?.id) return;

    updateEvents(event.id, data)
      .finally(() => {
        list();
        onCloseDrawer();
        toast({
          title: "Evento atualizado com sucesso",
          status: "success",
          duration: 4000,
          isClosable: true,
        })
      })
      .catch(() => {
        toast({
          title: "Erro ao atualizar evento.",
          description: "Verifique se o evento ainda existe.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  }

  function renderActionButtons(event: IEvent) {
    return (
      <div className="action-buttons">
        <IconButton
          disabled={new Date(event.finalDate) <= new Date()}
          colorScheme="blue"
          aria-label="Editar evento"
          icon={<EditIcon />}
          onClick={() => middlewareEdit(event)}
        />
        <IconButton
          isDisabled={new Date(event.finalDate) <= new Date()}
          title={event.is_active ? "Inativar evento" : "Ativar evento"}
          colorScheme={event.is_active ? "red" : "green"}
          aria-label={event.is_active ? "Inativar evento" : "Ativar evento"}
          icon={<CheckIcon />}
          onClick={() => middlewareActive(event)}
        />
      </div>
    );
  }

  return (
    <>
      <TableContainer className="table-container">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Banner</Th>
              <Th >Título</Th>
              <Th >Status</Th>
              <Th>Data de expiração</Th>
              <Th
                align="center"
                justifyContent={"center"}
                alignItems={"center"}
              >
                Endereço
              </Th>
            
              <Th>Ações</Th>
            </Tr>
          </Thead>
          <Tbody className="tr">
            {events.map((event) => (
              <Tr key={event.id}>
                <Td>
                  <Image src={event.image} width={140} height={20} />
                </Td>
                <Td>{event.title}</Td>
                <Td color={event.is_active ? 'green' : 'red'}><strong>{event.is_active ? 'ATIVO' : 'INATIVO'}</strong></Td>
                <Td> {moment(event.finalDate?.toString()).format('DD/MM/YYYY HH:mm:ss')} </Td>

                  <Td>
                    {event?.address?.city} - {event?.address?.state}
                  </Td>

                  <Td>{renderActionButtons(event)}</Td>
                </Tr>
              ))
            }
          </Tbody>
        </Table>
      </TableContainer>

      <Alert
        title={"Deletar evento"}
        confirmActionMessage={`Tem certeza que deseja ${isActive ? 'ATIVAR' : 'DESATIVAR'} este evento ?`}
        confirmActionButtonText={isActive ? 'ATIVAR' : 'DESATIVAR'}
        cancelActionButtonText={"Cancelar"}
        isOpen={isOpen}
        onClose={onClose}
        cancelRef={cancelRef}
        onConfirm={handleConfirmActive}
        confirmButtonText={""}
      />

      <Drawer
        size={"xl"}
        title={"Editar evento"}
        isOpen={isOpenDrawer}
        onClose={onCloseDrawer}
        cancelText={"Cancelar"}
        confirmText={"Salvar"}
        children={
          <EventForm
            onSubmit={handleUpdate}
            initialValues={{ ...event, ...event?.category, ...event?.address, company_id: event?.company_id } as IEvent}
            eventId={event?.id}
            onClose={onCloseDrawer}
          />
        }
      />
    </>
  );
}
