import { useEffect, useState } from "react";
import { TemplateLayout } from "../TemplateLayout";
import { LineChart } from "./components/Charts/LineChart";
import { IDashboardOrganizer } from "../../interfaces/dashboard-organizer";
import { dashboardOrganizer } from "../../services/api";
import { Button, HStack, Input, Select } from "@chakra-ui/react";
import { Label } from "reactstrap";
import { formatDateString } from "../../util/date";
import {MoneyCard} from "../../components/Cards/MoneyCard";
import { InfoCard } from "../../components/Cards/InfoCard";

enum SeriesEnum {
    brute = 'brute',
    liquid = 'liquid'
}

export const DashboardOrganizer = () => {

    const toLocalISOString = (date: Date): string => {
        const offset = date.getTimezoneOffset();
        const localDate = new Date(date.getTime() - offset * 60 * 1000);
        return localDate.toISOString().split('T')[0];
    }

    const getFirstDayOfMonth = () => {
        const now = new Date();
        now.setDate(1); // Define o dia para o primeiro do mês
        return toLocalISOString(now); // Converte para string no formato correto
      };

    const [series, setSeries] = useState<{ name: string; data: number[] }[]>([
        {
          name: "Receita",
          data: [],
        },
      ]);
    const [categories, setCategories] = useState<string[]>([])
    const [revenueType, setRevenueType] = useState<string>(SeriesEnum.brute)
    const [isInitLoad, setIsInittLoad] = useState<boolean>(true)
    const [startDate, setStartDate] = useState<string>(getFirstDayOfMonth())
    const [endDate, setEndDate] = useState<string>(toLocalISOString(new Date()))

    const [totalRaised, setTotalRaised] = useState<number>(0)
    const [netRevenue, setNetRevenue] = useState<number>(0)
    const [ticketsSold, setTicketsSold] = useState<number>(0)
    const [activeEvents, setActiveEvents] = useState<number>(0)
    
    const [expiredOrders, setExpiredOrders] = useState<number>(0)
    const [canceledOrders, setCanceledOrders] = useState<number>(0)
    const [failedOrders, setFailedOrders] = useState<number>(0)
    const [ratesApplied, setRatesApplied] = useState<number>(0)

    const getDataChart = async (value: SeriesEnum = SeriesEnum.brute) => {

        const {data} = await dashboardOrganizer(startDate, endDate)
        const result = data.data as IDashboardOrganizer

        const categoriesData = Object.keys(result.dataChart).map(data => {
            return formatDateString(data)
        })
        const seriesData = Object.values(result.dataChart).map(data => data[value])

        setCategories(categoriesData)
        setSeries((oldState) => [
            {
                ...oldState[0],
                data: seriesData
            }
        ])
        setTotalRaised(result.topCards.totalRaised)
        setNetRevenue(result.topCards.netRevenue)
        setTicketsSold(result.topCards.ticketsSold)
        setActiveEvents(result.topCards.activeEvents)

        setExpiredOrders(result.bottomCards.expiredOrders)
        setCanceledOrders(result.bottomCards.canceledOrders)
        setFailedOrders(result.bottomCards.failedOrders)
        setRatesApplied(result.bottomCards.ratesApplied)
    }

    const initLoad = async () => {
        await getDataChart()
        setIsInittLoad(false)
    }

    useEffect(() => {
        if(!isInitLoad){
            getDataChart(revenueType as SeriesEnum)
        }
    },[revenueType])

    useEffect(() => {
        initLoad()
    },[])

    return (
        <TemplateLayout>
            <h1>Dashboard</h1>

            <HStack justifyContent={'center'}>
                <MoneyCard
                    title="Total Arrecadado"
                    value={totalRaised}
                />

                <MoneyCard
                    title="Receita Líquida"
                    value={netRevenue}
                />
                
                <InfoCard
                    title="Ingressos Vendidos"
                    value={ticketsSold}
                />
                
                <InfoCard
                    title="Eventos Ativos"
                    value={activeEvents}
                />
            </HStack>

            <div
                style={{
                    marginTop: '50px',
                    display: 'flex',
                    justifyContent: 'center'
                }}  
            >
                <Input
                    style={{
                        width:'10rem'
                    }}
                    className="input"
                    name="initialDate"
                    placeholder="Data de inicio"
                    size="md"
                    type="date"
                    onChange={(e) => setStartDate(e.target.value.split('T')[0])}
                    value={startDate}
                    errorBorderColor="pink.400"
                />

                <Label
                    style={{
                        margin: '10px 20px 10px 20px'
                    }}
                >à</Label>

                <Input
                    style={{
                        width:'10rem'
                    }}
                    className="input"
                    name="endDate"
                    placeholder="Data final"
                    size="md"
                    type="date"
                    onChange={(e) => setEndDate(e.target.value.split('T')[0])}
                    value={endDate}
                    errorBorderColor="pink.400"
                />

                <Select 
                    marginLeft={10}
                    width={180} 
                    required 
                    value={revenueType} 
                    onChange={(e) => setRevenueType(e.target.value)}
                >
                    <option value={SeriesEnum.brute}>Tipo de receita</option>
                    <option value={SeriesEnum.brute}>Bruta</option>
                    <option value={SeriesEnum.liquid}>Liquida</option>
                </Select>

                <Button
                    marginLeft={50}
                    colorScheme="blue"
                    onClick={() => getDataChart(revenueType as SeriesEnum)}
                >
                    Buscar
                </Button>
            </div>
                
            <LineChart series={series} categories={categories}/>

            <HStack justifyContent={'center'} marginBottom={100}>
                <MoneyCard
                    title="Taxas Aplicadas"
                    value={ratesApplied}
                />

                <InfoCard
                    title="Pedidos expirados"
                    value={expiredOrders}
                />
                
                <InfoCard
                    title="Pedidos cancelados"
                    value={canceledOrders}
                />
                
                <InfoCard
                    title="Não autorizados"
                    value={failedOrders}
                />
            </HStack>
        </TemplateLayout>
      );
}