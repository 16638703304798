import React from 'react';
import { Box, Text, VStack, useBreakpointValue } from '@chakra-ui/react';

type CardProps = {
  title: string;
  value: number;
};

export const InfoCard: React.FC<CardProps> = ({ title, value }) => {

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      boxShadow="lg"
      p={4}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      bg="white"
      width={useBreakpointValue({ base: '100%', sm: '300px' })}
    >
      <Text fontSize="lg" fontWeight="bold" mb={2}>
        {title}
      </Text>
      <Text fontSize="2xl" fontWeight="bold" color="#993399">
        {value}
      </Text>
    </Box>
  );
};
