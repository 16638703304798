import React from "react";
import { Grid, GridItem, Box } from "@chakra-ui/react";
import Sidebar from "../../components/Sidebar";
import { Header } from "../../components/Header";

export const TemplateLayout = ({ children }: any) => {
    return (
        <Grid
          templateColumns="auto 1fr"
          templateRows="auto 1fr"
          minH="100vh"
          gap={0}
          overflow="hidden"  // Remove o scroll global
        >
          {/* Sidebar sempre fixo na lateral esquerda */}
          <GridItem
            as="aside"
            colSpan={1}
            rowSpan={2}
            bg="gray.100"
            h="100vh"
            position="sticky"
            top="0"
          >
            <Sidebar />
          </GridItem>
    
          {/* Header sempre no topo */}
          <GridItem as="header" colStart={2} colSpan={1} bg="gray.200" w="100%">
            <Header />
          </GridItem>
    
          {/* Conteúdo dinâmico com rolagem somente no conteúdo */}
          <GridItem
            colStart={2}
            rowStart={2}
            w="100%"
            maxH="calc(100vh - 60px)"  // Definindo altura dinâmica para o conteúdo, subtraindo a altura do header
            overflowY="auto"  // Só rolar o conteúdo
            p={4}
          >
            <Box>{children}</Box>
          </GridItem>
        </Grid>
    );
};
