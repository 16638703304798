// 👇️ ts-nocheck ignores all ts errors in the file
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

import { useState } from "react";
import { GoogleMap, LoadScript, Marker, StandaloneSearchBox, } from '@react-google-maps/api';
import "./style.css";
import { ILocation } from "../../screens/Event/components/EventForm";

interface MapProps {
  latitude: number;
  longitude: number;
  setAddress: (address: ILocation) => void;
  onChange: (latitude: number, longitude: number) => void;
}

export default function Map({ latitude, longitude, onChange, setAddress }: MapProps) {
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [map, setMap] = useState<google.maps.Map>();
  const [postalCode, setPostalCode] = useState<string | null>(null);
  latitude = latitude || -7.522596;
  longitude = longitude || -46.055221;

  const onLoad = (ref: google.maps.places.SearchBox) => {
    setSearchBox(ref);
  };
  const onPlacesChanged = () => {
    const places = searchBox!.getPlaces();
    console.log(`places ${JSON.stringify(places, null, 2)}`);
    const place = places![0];

    const payload = {
      city: place?.address_components?.find(component => component.types.includes("administrative_area_level_2"))?.long_name || "N/A",
      state: place?.address_components?.find(component => component.types.includes("administrative_area_level_1"))?.long_name || "N/A",
      neighborhood: place?.address_components?.find(component => component.types.includes("sublocality_level_1"))?.long_name || "N/A",
      street: place?.address_components?.find(component => component.types.includes("route"))?.long_name || "N/A",
      number: place?.address_components?.find(component => component.types.includes("street_number"))?.long_name || "N/A",
      postalCode,
    };

    setAddress(payload);

    const location = {
      lat: place?.geometry?.location?.lat() || 0,
      lng: place?.geometry?.location?.lng() || 0,
    };

    getPostalCode(location.lat, location.lng);

    onChange(location.lat, location.lng);

    map?.panTo(location);
  }

  const getPostalCode = (lat: number, lng: number) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location: { lat, lng } }, (results, status) => {
      if (status === "OK" && results) {
        const postalCodeComponent = results
          .flatMap(result => result.address_components)
          .find(component => component.types.includes("postal_code"));
  
        const newPostalCode = postalCodeComponent?.long_name || "N/A";
        setPostalCode(newPostalCode);
  
        const updatedAddress = {
          city: results[0]?.address_components?.find(component => component.types.includes("administrative_area_level_2"))?.long_name || "N/A",
          state: results[0]?.address_components?.find(component => component.types.includes("administrative_area_level_1"))?.long_name || "N/A",
          neighborhood: results[0]?.address_components?.find(component => component.types.includes("sublocality_level_1"))?.long_name || "N/A",
          street: results[0]?.address_components?.find(component => component.types.includes("route"))?.long_name || "N/A",
          number: results[0]?.address_components?.find(component => component.types.includes("street_number"))?.long_name || "N/A",
          postalCode: newPostalCode,
        };
  
        setAddress(updatedAddress);

        onChange(lat, lng);
        
        console.log("Endereço atualizado:", updatedAddress);
      }
    });
  };

  const onMapLoad = (map: google.maps.Map) => {
    setMap(map);
  }


  return (
    <div className="containerMap">
      <LoadScript
        googleMapsApiKey={"AIzaSyB9Wh61wpi0S-xl0aNGPOu9UFBfW11wkYk"}
        libraries={['places']}
      >

        <StandaloneSearchBox
            onLoad={onLoad}
            onPlacesChanged={onPlacesChanged}
          >
            <div className="inputContainer">
              <input className="addressField" placeholder="Digite um endereço " />
                <span className="inputIcon">📍</span>
            </div>
        </StandaloneSearchBox>
        <GoogleMap
          id="fb6e84868b647e12"
          onLoad={onMapLoad}
          mapContainerStyle={{ width: '100%', height: '100%', }}
          center={{
            lat: latitude,
            lng: longitude
          }}
          zoom={15}
          onClick={(e) => {
            onChange(e.latLng.lat(), e.latLng.lng());
            getPostalCode(e.latLng.lat(), e.latLng.lng());
          }}
        >
          <Marker position={{
            lat: latitude,
            lng: longitude
          }}
          />
        </GoogleMap>
      </LoadScript>
    </div>
  );
  
}
