import React from "react";
import ReactApexChart from "react-apexcharts";

type SeriesType = {
    series: { name: string; data: number[] }[];
    categories: string[]
}

export const LineChart: React.FC<SeriesType> = ({series, categories}) => {
  const options: ApexCharts.ApexOptions = {
    chart: {
      type: "line",
      height: 500,
      zoom: { enabled: false },
    },
    colors: ["#993399"],
    dataLabels: { enabled: false },
    stroke: { curve: "smooth" },
    markers: {
      size: 6, // Tamanho do marcador
      colors: ["#993399"], // Cor dos pontos
      strokeColors: "#993399", // Cor da borda dos pontos
      strokeWidth: 2, // Espessura da borda
      hover: {
        size: 8, // Aumenta o tamanho quando passa o mouse
      },
    },
    fill: {
      type: "gradient", // Aplica degradê abaixo da linha
      gradient: {
        shadeIntensity: 1, // Intensidade do gradiente
        opacityFrom: 0.9, // Opacidade no início do gradiente
        opacityTo: 0, // Opacidade no final do gradiente (transparente)
        stops: [0, 100], // Posições do gradiente
      },
    },
    title: { text: "Receitas diárias", align: "left" },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"], // Alternando cores das linhas
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: (value) => `R$ ${value.toFixed(2)}`, // Adiciona o símbolo de real e formata com duas casas decimais
      },
    },
  };

  return (
    <div>
      <ReactApexChart options={options} series={series} type="area" height={500} width={'98%'}/>
    </div>
  );
};
