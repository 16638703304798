import * as Yup from 'yup';
const validation = Yup.object().shape({
    title: Yup.string().required('Título do evento é obrigatório'),
    description: Yup.string().nullable(),
    quantity: Yup.number().required('Quantidade é obrigatório'),
    initialDate: Yup.date().required('Data inicial do ingresso é obrigatório'),
    finalDate: Yup.date().required('Data final do ingresso é obrigatório'),
    price: Yup.number().required('Preço é obrigatório'),
});

export default validation;