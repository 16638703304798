import dashboardIcon from '../../assets/dashboard_icon.png'

export const DashboardIcon = () => {

    return(
        <div
            style={{
                height: '1rem',
                width: '1rem',
            }}
        >
            <img src={dashboardIcon} alt="Dashboard"/>
        </div>
    )
}